body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url('assets/fonts/Avenir-Book.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url('assets/fonts/Avenir-Heavy.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim'),
    url('assets/fonts/GT-Walsheim-Bold.otf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim'),
    url('assets/fonts/GT-Walsheim-Medium.otf') format('typeType');
  font-weight: bolder;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim'),
    url('assets/fonts/GT-Walsheim-Light.otf') format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: local('GT-Walsheim'),
    url('assets/fonts/GT-Walsheim-Regular.otf') format('truetype');
  font-weight: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'GT-Walsheim';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
